import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

if (document.body.classList.contains('homepage')) {

    ScrollTrigger.matchMedia({

        // desktop
        "(min-width: 992px)": function() {


            gsap.to('.home-pbanner__imagep--1', {
                scrollTrigger: {
                    scrub: .5,
                    scroller: ".s-ct",
                    trigger: '.home-pbanner',
                    start: 'top bottom',
                    end: "bottom top",
                    //markers: true,
                },
                y: '-5%',
            });
            gsap.to('.home-pbanner__imagep--2', {
                scrollTrigger: {
                    scrub: .5,
                    scroller: ".s-ct",
                    trigger: '.home-pbanner',
                    start: 'top bottom',
                    end: "bottom top",
                    //markers: true,
                },
                y: '5%',
            });

        },
        "all": function() {

            gsap.to('.home-slide--2', {
                scrollTrigger: {
                    scrub: .5,
                    scroller: ".s-ct",
                    trigger: '.home-slide--1',
                    start: 'top top',
                    end: "bottom center",
                    //markers: true,
                },
                y: '-50%',
            });

            gsap.to('.home-slide--3', {
                scrollTrigger: {
                    scrub: .5,
                    scroller: ".s-ct",
                    trigger: '.home-slide--2',
                    start: 'top center',
                    end: "bottom bottom",
                    //markers: true,
                },
                y: '-50%',
            });

        }

    });

}