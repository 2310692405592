import jQuery from "jquery";
var Isotope = require('isotope-layout');
//var Packery = require('isotope-packery');

function concatValues( obj ) {
    var value = '';
    for ( var prop in obj ) {
        value += obj[ prop ];
    }
    return value;
}
var buttonFilters = {};
var buttonFilter = '*';
var $products;

window.addEventListener("resize", () => {
    //location.reload();

    if (document.body.classList.contains('lista-prodotti')) {
        $('.prodlist-grid__box-banner').height($('.prodlist-grid__box-content').height()+48);
    }

});


jQuery(document).ready(function ($) {

    if (document.body.classList.contains('lista-prodotti')) {

        setTimeout(function () {
            $products = new Isotope( '.prodlist-grid__wrap', {
                itemSelector: '.prodlist-grid__box',
                layoutMode: 'fitRows',
                resizable: false, // disable normal resizing
                filter: function() {
                    var $this = $(this);
                    return $this.is( buttonFilter );
                }

            });
            $('.prodlist-grid__box-banner').height($('.prodlist-grid__box-content').height()+48);
        },500);

        $('.prodlist-filters__reset').click(function (e) {

            e.preventDefault();

            $('.prodlist-filters__filter').remove();

            buttonFilters = [];
            buttonFilter = concatValues( buttonFilters ) || '*';
            $products.arrange();
            if ($products.filteredItems.length == 0) {
                $('#noResultsContainer').fadeIn();
            } else  {
                $('#noResultsContainer').fadeOut(1);
            }
            window.dispatchEvent(new Event('resize'));

        });

        $('select[name=categoria]').on('change',function () {
            window.location.href = $(this).val();
        });

        $('select[name=orderby]').on('change',function () {
            //window.location.href = $(this).val();
            alert('reload della pagina con parametro per ordinare');
        });


        $('.prodlist-filters__save').click(function () {
            $('.prodlist-filters').removeClass('open');
        });

        $('.prodlist-filters__open').click(function () {
            $('.prodlist-filters').addClass('open');
        });



        $('.prodlist-filters__tag').on('change',function () {
            var val = $(this).val();
            var cl = $(this).data('tag')+'-filter';
            var tg = $(this).data('tag');
            if (val != '') {
                var ico = $('#filter-ico').html();
                if ($('#filter-'+val).length == 0) {
                    $('.'+cl).remove();
                    var filterStart = '<a data-tag="'+tg+'" id="filter-'+val+'" class="'+cl+' prodlist-filters__filter" href="javascript:void(0)">';
                    $('.prodlist-filters__list').append(filterStart+val+ico+'</a>');
                }

                if (window.matchMedia("(min-width: 992px)").matches) {
                    $(this).val(null).trigger('change');
                }


                var filterGroup = $(this).attr('data-tag');
                buttonFilters[ filterGroup ] = '.'+filterGroup+'_'+val;
                buttonFilter = concatValues( buttonFilters ) || '*';

                $products.arrange();
                if ($products.filteredItems.length == 0) {
                    $('#noResultsContainer').fadeIn();
                } else  {
                    $('#noResultsContainer').fadeOut(1);
                }
                window.dispatchEvent(new Event('resize'));

            }
        });

        $('.prodlist-filters__list').on('click','.prodlist-filters__filter',function () {
            if ($(this).hasClass('prodlist-filters__filter-cat')) {
                console.log('cat');

            } else {
                $(this).remove();
                var tg = $(this).data('tag');
                delete buttonFilters[tg]
                buttonFilter = concatValues( buttonFilters ) || '*';
                $products.arrange();
                if ($products.filteredItems.length == 0) {
                    $('#noResultsContainer').fadeIn();
                } else  {
                    $('#noResultsContainer').fadeOut(1);
                }
                window.dispatchEvent(new Event('resize'));
            }

        });


    }


});