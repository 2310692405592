
if (window.matchMedia("(min-width: 992px)").matches) {
    //MENU DESKTOP
    $('.dropdown__main a').mouseenter(function () {
        $('.dropdown__sub').removeClass('active');
        $('.dropdown__last').removeClass('active');
        $('.dropdown__main-image').removeClass('active');
        $('.dropdown__banner').removeClass('active');
        var targ = $(this).data('target');
        if (targ != "") {
            $(targ).addClass('active');
        }
        var image = $(this).data('image');
        if (image != '') {
            $('.dropdown__main-image').attr('src',image);
            $('.dropdown__main-image').addClass('active');
        } else {
            $('.dropdown__banner').addClass('active');
        }
    });

    $('.dropdown__sub a').mouseenter(function () {
        $('.dropdown__last').removeClass('active');
        $('.dropdown__main-image').removeClass('active');
        $('.dropdown__banner').removeClass('active');
        var targ = $(this).data('target');
        if (targ != "") {
            $(targ).addClass('active');
        }
        var image = $(this).data('image');
        if (image != '') {
            $('.dropdown__main-image').attr('src',image);
            $('.dropdown__main-image').addClass('active');
        } else {
            $('.dropdown__banner').addClass('active');
        }
    });

    $('.dropdown__last a').mouseenter(function () {
        $('.dropdown__main-image').removeClass('active');
        $('.dropdown__banner').removeClass('active');
        var image = $(this).data('image');
        if (image != '') {
            $('.dropdown__main-image').attr('src',image);
            $('.dropdown__main-image').addClass('active');
        } else {
            $('.dropdown__banner').addClass('active');
        }
    });

    $('.dropdown__area').mouseleave(function () {
        $('.dropdown__sub').removeClass('active');
        $('.dropdown__last').removeClass('active');
        $('.dropdown__main-image').removeClass('active');
        $('.dropdown__sub-image').removeClass('active');
        $('.dropdown__last-image').removeClass('active');
        $('.dropdown__banner').addClass('active');
    });

} else {
    //MENU MOBILE
    $('.dropdown__main a').click(function (e) {

        var targ = $(this).data('target');
        if (targ != "") {
            e.preventDefault();
            $('.secondary-menu').css('display','none');
            $('.dropdown__main').css('display','none');
            $('.dropdown__sub').removeClass('active');
            $('.dropdown__last').removeClass('active');
            $('.dropdown__main-image').removeClass('active');
            $('.dropdown__banner').removeClass('active');
            $(targ).addClass('active');
        }
        var image = $(this).data('image');
        if (image != '') {
            $('.dropdown__main-image').attr('src',image);
            $('.dropdown__main-image').addClass('active');
        } else {
            $('.dropdown__banner').addClass('active');
        }
    });

    $('.dropdown__back').click(function () {

        var targ = $(this).data('target');
        if (targ != "") {
            $('.dropdown__main').css('display','none');
            $('.dropdown__sub').removeClass('active');
            $('.dropdown__last').removeClass('active');
            $('.dropdown__main-image').removeClass('active');
            $('.dropdown__banner').removeClass('active');
            if (targ == 'dropdownsub-0') {
                $('.dropdown__main').css('display','block');
                $('.secondary-menu').css('display','block');
            } else {
                $(targ).addClass('active');
            }

        }
    });

    $('.dropdown__sub a').click(function (e) {

        var targ = $(this).data('target');
        if (targ != "") {
            e.preventDefault();
            $('.dropdown__last').removeClass('active');
            $('.dropdown__sub').removeClass('active');
            $('.dropdown__main-image').removeClass('active');
            $('.dropdown__banner').removeClass('active');
            $(targ).addClass('active');
        }
        var image = $(this).data('image');
        if (image != '') {
            $('.dropdown__main-image').attr('src',image);
            $('.dropdown__main-image').addClass('active');
        } else {
            $('.dropdown__banner').addClass('active');
        }
    });

}

$('.open-menu').click(function () {
    $('.dropdown').toggleClass('open')
    $(this).toggleClass('close');
});

