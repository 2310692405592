import jQuery from "jquery";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

jQuery(document).ready(function ($) {

    if (document.body.classList.contains('dettaglio-prodotto')) {

        let thumbCarousel = jQuery('.product-views').slick({
            arrows: true,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: true,
            verticalSwiping: true,
            infinite: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToScroll: 3,
                        arrows: false,
                        dots: true,
                        vertical: false,
                        verticalSwiping: false,
                    }
                },
            ]
        });

        $('.product-top__colors a').click(function () {
            var targ = '#productvar_'+$(this).attr('rel');
            $(targ).trigger('click');
        });


        ScrollTrigger.create({
            scroller: ".s-ct",
            trigger: '#overview',
            start: 'top-=200 top',
            end: "bottom top",
            //markers: true,
            onToggle: self => {
                if (self.isActive) {
                    $('#overview-btn').addClass('active');
                } else {
                    $('.product-navigation a').removeClass('active');
                }
            },
        });

        ScrollTrigger.create({
            scroller: ".s-ct",
            trigger: '#caratteristiche',
            start: 'top-=64 top',
            end: "bottom top",
            //markers: true,
            onToggle: self => {
                if (self.isActive) {
                    $('#feature-btn').addClass('active');
                } else {
                    $('.product-navigation a').removeClass('active');
                }
            },
        });

        ScrollTrigger.create({
            scroller: ".s-ct",
            trigger: '#dati-tecnici',
            start: 'top-=16 top',
            end: "bottom top",
            //markers: true,
            onToggle: self => {
                if (self.isActive) {
                    $('#tech-btn').addClass('active');
                } else {
                    $('.product-navigation a').removeClass('active');
                }
            },
        });

        ScrollTrigger.create({
            scroller: ".s-ct",
            trigger: '#comparatore',
            start: 'top-=8 top',
            end: "bottom top",
            //markers: true,
            onToggle: self => {
                if (self.isActive) {
                    $('#comparatore-btn').addClass('active');
                } else {
                    $('.product-navigation a').removeClass('active');
                }
            },
        });



        $('.product-navigation a').click(function () {
            $('.product-navigation a').removeClass('active');
            $(this).addClass('active');
            var tar = $(this).data('target');

            document.getElementById(tar).click();

        });

        $('.product-top__tec').click(function (){
            document.getElementById('tech-trigger').click();

        });

        var relatedCarousel = $('.product-related__slider').slick({
            arrows: false,
            dots: true,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 720,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToScroll: 1,
                        slidesToShow: 1,
                    }
                }
            ]
        });


        $('.product-views a').click(function () {

            var srcset = $(this).data('srcset');
            var src  = $(this).data('src');
            var sizes = $(this).data('sizes');

            $('.product-views a').removeClass('active')
            $(this).addClass('active');

            var tl = new gsap.timeline({
                onStart: () => {

                },
                onComplete: () => {

                }
            });
            tl
            .to('.product-top__image',{
                duration: .5,
                opacity: '0'
            })
            .add(function () {


                $('.product-top__image-wrap').attr('href',src);
                $('.product-top__image').attr('src',src);
                $('.product-top__image').attr('srcset',srcset);
                $('.product-top__image').attr('sizes',sizes);

            })
            .to('.product-top__image',{
                duration: .5,
                opacity: '1',
                delay: .25
            });

        });
    }


});