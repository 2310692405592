//Style
import '../scss/style.scss';
import 'slick-carousel/slick/slick.scss';
import '@fancyapps/fancybox/src/css/core.css';
import '@fancyapps/fancybox/src/css/thumbs.css';

//Javascript
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
export jQuery from "jquery";
import 'bootstrap';
import SmoothScroll from './smooth-scroll';
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import slick from "slick-carousel/slick/slick";
//import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import select2 from "select2";
import "select2/dist/css/select2.css";

require("@fancyapps/fancybox");
gsap.registerPlugin(ScrollTrigger);
window.ss=new SmoothScroll({el:document.querySelector("[data-scroll-container]"),smooth:window.activeSmooth,getDirection:!0});ss.on("scroll",ScrollTrigger.update),ScrollTrigger.scrollerProxy(document.querySelector("[data-scroll-container]"),{scrollTop(e){return arguments.length?ss.scrollTo(e,0,0):ss.scroll.instance.scroll.y},getBoundingClientRect:()=>({top:0,left:0,width:window.innerWidth,height:window.innerHeight}),pinType:document.querySelector("[data-scroll-container]").style.transform?"transform":"fixed"}),ScrollTrigger.addEventListener("refresh",()=>ss.update()),ScrollTrigger.refresh(),ss.on("scroll",e=>{e.scroll.y>300?document.querySelectorAll("header")[0].classList.add("sticky"):document.querySelectorAll("header")[0].classList.remove("sticky");e.scroll.y>100&&"down"==e.direction?document.querySelectorAll("header")[0].classList.add("hide"):document.querySelectorAll("header")[0].classList.remove("hide")});

//HEADER TRIGGER
window.ss.on('scroll',e=>{
    e.scroll.y>10?document.querySelectorAll("header")[0].classList.add("sticky"):document.querySelectorAll("header")[0].classList.remove("sticky");
    e.scroll.y>10&&"down"==e.direction?document.querySelectorAll("header")[0].classList.add("hide"):document.querySelectorAll("header")[0].classList.remove("hide");
    if (document.body.classList.contains('prodotto')) {
        e.scroll.y>10?document.querySelectorAll(".product-menu")[0].classList.add("sticky"):document.querySelectorAll(".product-menu")[0].classList.remove("sticky");
    }
});

window.addEventListener("load", () => {
    jQuery('body').addClass('loaded');
});

window.addEventListener("resize", () => {
    //location.reload();
});

require('./menu.js');
require('./home.js');
require('./lista-prodotti.js');
require('./prodotto.js');


jQuery(document).ready(function ($) {

    $('.screen-reader-text').removeClass('screen-reader-text');


    $('a.anchor').click(function (e) {
        e.preventDefault();
        var targ= $(this).attr('href');
        var t = $(targ).offset().top;
        ss.scrollTo(t,500);
    });

    $('.minicart-toggle').click(function (){

        if ($(this).hasClass('active')) {
            $(this).removeClass('active')
            $('.minicart').fadeOut();
        } else {
            $(this).addClass('active')
            $('.minicart').fadeIn();
        }

    });


    /*
    $('a').click(function (e) {
        var targ= $(this).attr('href');
        if (targ[0] == '#') {
            e.preventDefault();
            var t = $(targ).offset().top;
            ss.scrollTo(t,500);
        } else {
            //console.log('no');
        }
    });

     */

    $(".woocommerce-products-compare-compare-link").fancybox({
        type: "iframe"
    });

    $('.card-header').click(function () {
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        },500);
    });

    $('.product-video__play').click(function () {
        if ($(this).hasClass('off')) {
            $(this).siblings('video').trigger('pause');
            $(this).removeClass('off');
        } else {
            $(this).siblings('video').trigger('play');
            $(this).addClass('off');
        }

    });

    setTimeout(function () {

        $('.custom-select').each(function () {

            var drop = $(this).closest('.custom-select__ct');
            var wd = $(this).width() + 40;

            $(this).select2({
                minimumResultsForSearch: Infinity,
                dropdownParent: drop,
                width: wd
            });

            if ($(this).hasClass('error')) {
                $(this).siblings('.select2').addClass('error');
            }
        });
    },100);

});
